<template>
  <div class="home">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="Homecontainer">
        <el-carousel height="600px">
          <el-carousel-item v-for="(item,index) in headBanner" :key="index">
              <img :src="item.src" alt="" class="headBanImg">
            <div class="headBannerContent">
              <div style="z-index:10;color:#fff;font-size: 46px;padding-bottom:40px;text-shadow: 0px 4px 16px rgba(0,0,0,0.2);">{{item.title}}</div>
              <div style="z-index:10">
                <button class="inuptBtnTop" @click="$router.push(item.goPath)">了解更多</button>
                <button class="lookVadio" @click="play = true">观看视频</button>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="hrContent">
        <h5>智慧HR--人力资源生命周期全覆盖</h5>
        <span class="line"></span>
        <ul class="hrNav">
          <li
            v-for="(item, index) in HrNav"
            :key="index"
            :class="{ shadow: HrShow === index }"
            @click="HrShow = index"
          >
            <img :src="item.src" alt="" />
            <p>{{ item.title }}</p>
            <span class="hrNavSpan" v-if="HrShow === index" style="z-index:100"></span>
          </li>
        </ul>
        <div class="hrIndex">
          <div v-for="(item, index) in HrIndex" :key="index">
            <template v-if="HrShow === index">
              <div class="hrInContent">
                <div class="indexLeft">
                  <div style="font-size: 30px; font-weight: 500">
                    {{ item.left.title }}
                  </div>
                  <span class="hrIndexSpan"></span>
                  <p style="color: #444; line-height: 30px">{{ item.left.p }}</p>
                  <button class="inuptBtn" @click="$router.push('/freeTrial')">免费试用</button>
                  <button class="inuptBtnmore buttonRed" @click="$router.push(item.left.path)">了解更多</button>
                </div>
                <img :src="item.right.src" alt="" />
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="coreBus">
        <div class="coreDesc">
          <h5>高校人力资源领域深耕细作</h5>
          <p>专业源于专注 专注产生价值</p>
          <span class="line" style="margin-top: 18px"></span>
          <ul class="coreDescContent">
            <li v-for="(item, index) in coreDescList" :key="index"  @mouseenter="coreBusActiveIndex = index" @mouseleave="coreBusActiveIndex=null">
              <div class="coreDescContentRight">
                <img :src="coreBusActiveIndex===index?item.src2:item.src" alt=""/>
              </div>
              <div class="right">
                <h6>{{ item.title }}</h6>
                <p style="line-height:24px">{{ item.p }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="schoolContent">
        <h5>400<span class="jiaTop" style="font-size:28px;margin-right:5px">+</span>高校的最优选择</h5>
        <span class="line" style="margin:20px 0 40px"></span>
        <div class="schoolValueCon">
          <div class="schoolBtnCon" ref="wisdomList">
            <div v-for="(item,index) in schoolList" :key="index" :class="{schoolBtnItem:true,schoolBtnItemActive:index===wisdomShow}" @click="changeItem(index)">
              <img src="../assets/home/wave.png"
                :class="{
                wisdomWave: true,
                wisdomWaveindex: index===wisdomShow,
              }"/>
              <span>
                {{item.btnTitle}}
                <span class="jiaschoolTop" v-if="item.jia">{{item.jia}}</span>
                <span v-if="item.btnTitle2">{{item.btnTitle2}}</span>
                <span class="jiaschoolTop" v-if="item.jia2">{{item.jia2}}</span>
              </span>

            </div>
          </div>
          <div style="width:1200px;margin-bottom:40px">
            <div v-for="(item,index) in schoolList" :key="index" >
              <img :src="item.img" alt="" v-if="index===wisdomShow" style="width:1200px;margin-top:20px">
              <div v-if="index===wisdomShow" style="text-align:right;font-size:12px;color:#b0b0b0;width:1200px;margin:auto;padding-right:10px">排名不分先后</div>
            </div>
          </div>

        </div>
        <!-- <button class="buttonRed homeInuptBtnmore" style="margin:20px 0 40px" @click="$router.push('typicalCase/customer')">了解更多</button> -->
      </div>

      <Foot></Foot>
    </div>

    <div class="fullPageVideo" v-if="play">
      <div class="videoWrap">
        <span class="el-icon-error closePlay" @click="play = false"></span>
        <video width="100%" autoplay loop  src="@/assets/about/vidio.mp4" controls></video>
      </div>
    </div>
    <BackTop class="backTop"></BackTop>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      topActive: false,
      headBanner: [
        {
          src: require('../assets/home/2023Banner.png'),
          title: '高校人力资源数字化解决方案',
          goPath: 'solution/smartHr'
        },
        {
          src: require('../assets/home/bannerTwo.png'),
          title: '聚焦智慧人事，共创数智校园',
          goPath: 'typicalCase/typeCase'
        },
        {
          src: require('../assets/home/bannerThree.png'),
          title: '同行致远，未来可期',
          goPath: 'about'
        }
      ],
      coreDescList: [
        {
          src: require('../assets/home/coreStudy.png'),
          src2: require('../assets/home/coreStudyGF.png'),
          title: '业务专注专业',
          p: '专注于高校人力资源信息化领域，系统完全根据高校的治理模式和业务特点进行设计和开发，产品迭代升级到V9版本，以数据和服务为核心，服务驱动，数据赋能。'
        },
        {
          src: require('../assets/home/cpped.png'),
          src2: require('../assets/home/cppedGf.png'),
          title: '产品匹配度高',
          p: '根据高校人力资源管理与服务的特点进行产品的设计和开发，既能体现高校人力资源管理的普遍性要求，又能根据不同高校的个性化业务进行快速配置，满足不同高校的具体业务需求。'
        },
        {
          src: require('../assets/home/coreBus.png'),
          src2: require('../assets/home/coreBusGF.png'),
          title: '实施经验丰富',
          p: '400多所高校人力资源信息化项目的实施，积累了丰富的实施经验，能够最大程度地保障项目的成功实施。'
        },
        {
          src: require('../assets/home/coreServe.png'),
          src2: require('../assets/home/coreServeGF.png'),
          title: '业务理解深刻',
          p: '与中国高等教育学会以及多个高校的业务管理部门和专家进行高校人力资源相关的课题合作，保证团队对高校业务的深刻理解，这是做好“高校人力资源业务伙伴”的前提。'
        },
        {
          src: require('../assets/home/jspt.png'),
          src2: require('../assets/home/jsptGf.png'),
          title: '最佳业务实践',
          p: '整理不同高校用户的最佳业务实践，为新的用户提供借鉴和参考，在实施过程中为客户提供顾问式的服务，需求响应快，交付快。'
        },
        {
          src: require('../assets/home/ptnl.png'),
          src2: require('../assets/home/ptnlGf.png'),
          title: '平台能力强大',
          p: '高校的人力资源具有天生的发展性，业务需求不断变化。易普行《高校人力资源管理与服务平台》能够随着高校业务的发展而进行功能扩展，支持高校不断发展的业务需求。'
        }
      ],
      coreBusActiveIndex: null,
      HrNav: [
        {
          src: require('../assets/home/recruitmentManage.png'),
          title: '招聘管理系统'
        },
        { src: require('../assets/home/inReport.png'), title: '基础人事系统' },
        { src: require('../assets/home/szpy.png'), title: '师资培养与发展系统' },
        { src: require('../assets/home/gp.png'), title: '岗位评聘系统' },
        {
          src: require('../assets/home/performance.png'),
          title: '考核与评估系统'
        },
        { src: require('../assets/home/payment .png'), title: '薪酬福利系统' }
      ],
      HrIndex: [
        {
          left: {
            title: '招聘管理系统',
            p: '将学校招聘工作实现网络化管理，对招聘计划、职位发布、简历投递、简历筛选、招聘评审、笔试、面试等实现全过程的流程化管理，可以实现移动招聘，为管理者及应聘者提供便捷的服务。',
            path: '/product/productEmployment'
          },
          right: { src: require('@/assets/home/zhaoping.png') }
        },
        {
          left: {
            title: '基础人事系统',
            p: '以用户组织岗位体系、教职工信息库为基础，实现不同类别人员入转调离全生命周期基础人事业务管理，推动人事管理理念更新、模式变革、流程优化，构建用户智慧HR管理体系。',
            path: '/product/productBasePeople'
          },
          right: { src: require('@/assets/product/basePeople/personalIndex.png') }
        },
        {
          left: {
            title: '师资培养与发展系统',
            p: '师资培养与发展系统是对教师培训、国内进修、国外进修、挂职锻炼、外聘专家、教师资格考试、学术兼职、荣誉称号等业务的综合管理，助力师资队伍建设，促进教师职业发展。',
            path: '/product/productSzpyyfzxt'
          },
          right: { src: require('@/assets/product/ProductSzpyyfzxt/gaint.png') }
        },
        {
          left: {
            title: '岗位评聘系统',
            p: '岗位评聘系统是对岗位聘任、职称评审、聘期考核业务的综合管理，基于教职工绩效数据，可实现申报信息自动抽取、评审材料电子化、评审过程网络化及流程化、评聘过程全留痕。',
            path: '/product/productPosition'
          },
          right: { src: require('@/assets/product/position/gaint.png') }
        },
        {
          left: {
            title: '考核与评估系统',
            p: '考核与评估系统是针对教职工分类评价业务的管理。支持定性考核与定量考核等多种考核模式，通过对团体或个人的绩效进行全过程、全方位管理，有效提升单位的组织管理能力，充分调动教师工作积极性，为绩效工资核定发放提供标准。',
            path: '/product/productKhypg'
          },
          right: { src: require('@/assets/home/jiXiaoSystem.png') }
        },
        {
          left: {
            title: '薪酬福利系统',
            p: '在全面薪酬管理体系的指导下，基于人事处薪酬福利业务，以激励为目标导向，对教职工基本工资、绩效工资、社保、公积金、福利发放、合并发放计税等业务进行综合管理。',
            path: '/product/productPaySystem'
          },
          right: { src: require('@/assets/home/wageSystem.png') }
        }

      ],
      HrShow: 0,
      schoolList: [
        {
          img: require('@/assets/TypicalCase/Customer/syl.png'),
          btnTitle: '双一流院校70',
          jia: '+',
          btnTitle2: '，市场覆盖率50%',
          jia2: ' +'
        },
        {
          img: require('@/assets/TypicalCase/Customer/bk.png'),
          btnTitle: '本科院校200',
          jia: '+'
          // btnTitle2: '，市场覆盖率28%'
        },
        {
          img: require('@/assets/TypicalCase/Customer/zyyx.png'),
          btnTitle: '职业院校100',
          jia: '+'
          // btnTitle2: '，市场覆盖率16%'
        },
        {
          img: require('@/assets/TypicalCase/Customer/kkys.png'),
          btnTitle: '科研院所、医院50',
          jia: '+'
        }
      ],
      wisdomShow: 0,
      play: false
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  methods: {
    changeItem (value) {
      const lis = Array.from(this.$refs.wisdomList.children)

      this.wisdomShow = value
      lis.forEach((item) => {
        const btn = item.children[0]
        btn.style.bottom = '-24px'
        btn.style.right = '0px'
      })

      const curBtn = lis[value].children[0]
      curBtn.style.bottom = 0
      curBtn.style.right = '-300px'
    },
    // 通过监听可视窗口宽度转跳页面
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/mohome')
      }
    }
  }

}
</script>

<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  // color: #222;
  font-size: 16px;
  box-sizing: border-box;
}
body,
html {
  margin: 0;
  padding: 0;
}
img {
   object-fit: cover;
}
li {
  list-style: none;
}
button{
  font-size: 14px;
}
.buttonRed{
  color: #444;
}
.buttonRed:hover{
  color: #D70C0C;
}
button:hover{
  cursor: pointer;
}
.inuptBtn:hover{
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.homeInuptBtnmore {
  width: 120px;
  height: 40px;
  background: #ffffff;
  border-radius: 30px;
  border: 1px solid rgba(211, 218, 228, 0.5);
}

.home {
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}

.Homecontainer{
  width: 100%;
  height: 600px;
  position: relative;
  top: 0;
  left: 0;
}

.Homecontainer{
  &::v-deep {
      .el-carousel__button {
      width: 10px;
      height: 10px;
      background: #d70c0c !important;
      border-radius: 5px;
    }
  }
}

.headBannerContent{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headBanImg{
  width:100%;
  height:100%;
  position:absolute;
  top: 0;
  left: 0;
}
.inuptBtnTop {
  z-index:10;
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 18px;
  color: #fff;
  border: 0;
}
.inuptBtnTop:hover{
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.lookVadio{
  width: 200px;
  height: 60px;
  border-radius: 34px;
  border: 1px solid #FFFFFF;
  background-color: transparent;
  color: #fff;
  margin-left: 40px;
  font-size: 18px;
}
.lookVadio:hover{
  background-color: rgba(255, 255, 255,0.2);
}

.coreBus {
  width: 100%;
  height: 810px;
  background: url("../assets/home/curveBg.png") center no-repeat;
  background-size: cover;
  text-align: center;
}
.coreDesc {
  position: relative;
  top: 0;
  left: 0;
  width: 1200px;
  height: 100%;
  margin: auto;
}
.coreDesc h5 {
  font-size: 36px;
  padding-top: 60px;
  font-weight: 500;
  box-sizing: border-box;
  color: #222222;
}
.coreDesc p {
  padding-top: 20px;
  font-size: 24px;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.coreDescContent {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-top: 62px;
}
.coreDescContent li {
  min-width: 541px;
  max-width: 541px;
  margin-right: 118px;
  margin-bottom: 40px;
  box-sizing: border-box;
}
.coreDescContent li:nth-child(even) {
  margin-right: 0;
}
.coreDescContent .coreDescContentRight{
  float: left;
  width: 100px;
  height: 100px;
}
.coreDescContent .coreDescContentRight img {
  width: 100px;
  height: 100px;
}
.coreDescContent li .right {
  width: 400px;
  float: right;
  text-align: left;
}
.coreDescContent li .right h6 {
  font-size: 24px;
}
.coreDescContent li .right p {
  font-size: 16px;
  color: #444;
}

.hrContent {
  text-align: center;
}
.hrContent h5 {
  font-size: 36px;
  font-weight: 500;
  padding: 60px 0 10px 0;
}
.hrNav {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 40px 0;
}
.hrNav li {
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 154px;
}
.hrNav li img {
  min-width: 56px;
  height: 56px;
  margin-bottom: 16px;
}
.hrNavSpan {
  position: absolute;
  bottom: -42px;
  left: 0;
  width: 180px;
  height: 4px;
  background: #d70c0c;
}
.shadow {
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.hrIndex {
  width: 100%;
  height: 540px;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
}
.hrInContent {
  position: relative;
  top: 0;
  left: 0;
  width: 1200px;
  height: 100%;
  margin: auto;
  border-top: 1px solid rgba(211, 218, 228, 0.5);
  text-align: left;
}
.hrInContent .indexLeft {
  position: absolute;
  left: 0;
  top: 88px;
  width: 500px;
}
.hrIndexSpan {
  display: inline-block;
  width: 30px;
  height: 4px;
  background: #d70c0c;
  margin: 20px 0 15px;
}
.hrInContent img {
  position: absolute;
  right: 0;
  top: 49px;
  width: 640px;
}
.inuptBtn {
  width: 120px;
  height: 40px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 30px;
  border: 0px;
  color: #fff;
  position: absolute;
  top: 248px;
  left: 0px;
}
.inuptBtn:hover{
box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.inuptBtnmore {
  width: 120px;
  height: 40px;
  background: #ffffff;
  border-radius: 30px;
  border: 1px solid rgba(211, 218, 228, 0.5);
  position: absolute;
  top: 248px;
  left: 140px;
}

.schoolContent {
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.schoolContent h5 {
  font-size: 36px;
  font-weight: 500;
  padding: 60px 0 0px 0;
}
.schoolValueCon{
  width: 1200px;
}
.schoolBtnCon{
  display: flex;
  padding:0 10px;
}
.schoolBtnItem{
  width: 285px;
  height: 80px;
  position: relative;
  top: 0;
  left: 0;
  line-height: 80px;
  font-size: 17px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
  color: #222;
  border: 0;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
}
.schoolBtnItem:last-child{
  margin-right: 0px;
}
.schoolBtnItem:hover{
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  color: #fff;
}
.wisdomWave {
  display: block;
  position: absolute;
  width: 600px;
  height: 24px;
  right: 0px;
  bottom: -24px;
  transition: right 2s, bottom 1.8s;
}
.wisdomWaveindex {
  bottom: 0;
}
.schoolBtnItemActive{
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  color: #fff;
}
.activeSchoolTitle{
  color: #fff;
}
.jiaschoolTop{
  position: relative;
  top: -6px;
  left: -3px;
}

.fullPageVideo{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.2);
  z-index: 9999;
}
.closePlay{
  position: absolute;
  top: -50px;
  right: -100px;
  font-size: 50px;
  color: rgba(255, 255, 255,0.5);
  cursor: pointer;
}
.videoWrap{
  position: fixed;
  width: 900px;
  left: 50%;
  top: 55%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}
.jiaTop{
  position: relative;
  top: -10px;
  left: 0;
}
</style>
